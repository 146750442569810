import { FC, useContext } from "react";
import ercode from "../../images/customerService.jpeg";
import "./media.less";
import { CommonContext } from "../../../common/commonContext";

const ContactUsMobile: FC<any> = ({ setShowContact }) => {
  const { setShowService, showService } = useContext(CommonContext);
  return (
    <div className="contact-us-mobile-modal">
      <div className="contact-us-mobile">
        <div className="item header">
          <div className="header-title">联系我们</div>
          <span
            className="iconfont icon-close"
            onClick={() => setShowContact(false)}
          ></span>
        </div>
        <div className="item phone">
          <div>电话</div>
          <div className="right-text">010-87531591</div>
        </div>
        <div className="item email">
          <div>邮箱</div>
          <div className="right-text">xianyuan@frontis.ai</div>
        </div>
        <div className="item address">
          <div>地址</div>
          <div className="right-text">北京市海淀区首享科技大厦3层</div>
        </div>
        <div className="item code">
          <div className="left">官方客服</div>
          <img className="right-img" src={ercode} alt="" />
        </div>
        <div className="item contact">
          <div
            className="o-modal-btn"
            onClick={() => setShowService?.(showService ? "" : "click")}
          >
            合作咨询
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsMobile;
