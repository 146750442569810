import { FC, memo, useState } from "react";
import { Link } from "react-router-dom";
import { BASE_ROUTE_NAME } from "../../../config/index";
import useViewport from "../../../hooks/useViewport";
import MenuMobile from "../menuMobile";
import { scrollTop } from "../../../utils/scroll";
import logoWhite from "../../../images/logo-white.svg";
import officialAccount from "../../images/officialAccount.jpeg";
import customerService from "../../images/customerService.jpeg";
import weibo from "../../images/weibo.png";

const routes = [
  { path: `${BASE_ROUTE_NAME === "" ? "/" : BASE_ROUTE_NAME}`, name: "首页" },
  { path: `${BASE_ROUTE_NAME}/product`, name: "产品介绍" },
  { path: `${BASE_ROUTE_NAME}/solution/science`, name: "解决方案-科学发现" },
  { path: `${BASE_ROUTE_NAME}/solution/fb`, name: "解决方案-食品饮料" },
  { path: `${BASE_ROUTE_NAME}/solution/fmcg`, name: "解决方案-快消零售" },
  { path: `${BASE_ROUTE_NAME}/solution/mxx`, name: "解决方案-时尚潮流" },
  { path: `${BASE_ROUTE_NAME}/news`, name: "新闻中心" },
  { path: `${BASE_ROUTE_NAME}/about/introduce`, name: "公司介绍" },
  { path: `${BASE_ROUTE_NAME}/about/join`, name: "招贤纳士" },
];
const routesPc = [
  { path: `${BASE_ROUTE_NAME === "" ? "/" : BASE_ROUTE_NAME}`, name: "首页" },
  { path: `${BASE_ROUTE_NAME}/product/marketing`, name: "产品介绍" },
  { path: `${BASE_ROUTE_NAME}/solution/allEmployee`, name: "解决方案" },
  { path: `${BASE_ROUTE_NAME}/about/introduce`, name: "公司介绍" },
  { path: `${BASE_ROUTE_NAME}/news`, name: "新闻中心" },
  { path: `${BASE_ROUTE_NAME}/about/join`, name: "招贤纳士" },
];

const Footer: FC<{
  setShowContactUsMobile?: (val: boolean) => void;
}> = memo(({ setShowContactUsMobile }) => {
  const [hiddenWeibo, setHiddenWeibo] = useState(true);
  const [showMenuMobile, setShowMenuMobile] = useState(false);
  const { isMobile } = useViewport();

  if (isMobile)
    return (
      <>
        <footer>
          <div className="footer-content">
            <img src={logoWhite} alt="" className="footer-logo" />
            <dl className="footer-content-quick-entry">
              <dt onClick={() => isMobile && setShowMenuMobile(true)}>
                快速入口
              </dt>
              <dd>
                {routes?.map((item) => {
                  if (isMobile && item.name === "解决方案-科学发现")
                    return null;
                  if (!isMobile && item.name === "解决方案-时尚潮流")
                    return null;
                  return (
                    <p key={item.path} onClick={() => scrollTop()}>
                      <Link to={item.path} style={{ color: "#fff" }}>
                        {item.name}
                      </Link>
                    </p>
                  );
                })}
              </dd>
            </dl>
            <dl className="footer-contact-us">
              <dt onClick={() => isMobile && setShowContactUsMobile?.(true)}>
                联系我们
              </dt>
              <dd>
                <p>电话：010-87531591 </p>
                <p>邮箱：xianyuan@frontis.ai</p>
                <p>地址：北京市海淀区首享科技大厦3层</p>
              </dd>
            </dl>
            {isMobile ? (
              <dl className="footer-qrcode">
                <dd>
                  <div className="footer-qrcode-cot">
                    <p>微信公众号</p>
                    <img src={officialAccount} alt="" />
                  </div>
                  <div className="footer-qrcode-cot">
                    <p>微信客服</p>
                    <img src={customerService} alt="" />
                  </div>
                </dd>
              </dl>
            ) : (
              <dl className="footer-qrcode">
                <dt>微信公众号</dt>
                <dd>
                  <div className="footer-qrcode-cot">
                    <img src={officialAccount} alt="" />
                    <p>微信公众号</p>
                  </div>
                  <div className="footer-qrcode-cot">
                    <img src={customerService} alt="" />
                    <p>微信客服</p>
                  </div>
                </dd>
              </dl>
            )}
            <div className="footer-icons">
              {isMobile ? (
                <a
                  className="icon-box"
                  href="https://m.weibo.cn/u/7763030335?jumpfrom=weibocom"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <span className="iconfont icon-weibo"></span>
                </a>
              ) : (
                <div
                  className="icon-box"
                  onMouseEnter={() => setHiddenWeibo(false)}
                  onMouseLeave={() => setHiddenWeibo(true)}
                >
                  <span className="iconfont icon-weibo"></span>
                </div>
              )}
              <a
                className="icon-box"
                href="https://www.zhihu.com/org/xian-yuan-chan-pin-can-mou"
                target={"_blank"}
                rel="noreferrer"
              >
                <span className="iconfont icon-zhihu"></span>
              </a>
              <dl
                className="weibo-code-chart"
                style={{ display: hiddenWeibo ? "none" : "block" }}
              >
                <dt>- 官方微博 -</dt>
                <dd style={{ backgroundImage: `url(${weibo})` }}></dd>
              </dl>
            </div>
          </div>
          <div className="footer-copyright">
            Copyright © www.frontis.cn, All Rights Reserved.
            <br />
            全球智能化服务引领者
            <a
              style={{ color: "rgba(255, 255, 255, 0.5)", marginLeft: 4 }}
              href="https://beian.miit.gov.cn"
              target="_blank"
              rel="noreferrer"
            >
              京ICP备2022014486号-1
            </a>
            <br />
            模型名称:衔远品商大模型 ｜ 备案号：Beijing-PinShang-20240102 <br />
            模型名称:摹小仙｜ 备案号：Beijing-MoXiaoXian-202401002
          </div>
          {/* <div className="model-content">
          模型名称:衔远品商大模型 ｜ 备案号：Beijing-PinShang-20240102 <br />
          模型名称:摹小仙备案号：Beijing-MoXiaoXian-202401002
          </div> */}
        </footer>

        {showMenuMobile && <MenuMobile setShowMenuMobile={setShowMenuMobile} />}
      </>
    );
  return (
    <>
      <footer>
        <div className="footer-content">
          <img src={logoWhite} alt="" className="footer-logo" />
          <dl className="footer-content-quick-entry">
            <dt onClick={() => isMobile && setShowMenuMobile(true)}>
              快速入口
            </dt>
            <dd>
              {routesPc?.map((item) => {
                return (
                  <p key={item.path} onClick={() => scrollTop()}>
                    <Link to={item.path} style={{ color: "#fff" }}>
                      {item.name}
                    </Link>
                  </p>
                );
              })}
            </dd>
          </dl>
          <dl className="footer-contact-us">
            <dt onClick={() => isMobile && setShowContactUsMobile?.(true)}>
              联系我们
            </dt>
            <dd>
              <p>电话：010-87531591 </p>
              <p>邮箱：xianyuan@frontis.ai</p>
              <p>地址：北京市海淀区首享科技大厦3层</p>
            </dd>
          </dl>
          {isMobile ? (
            <dl className="footer-qrcode">
              <dd>
                <div className="footer-qrcode-cot">
                  <p>微信公众号</p>
                  <img src={officialAccount} alt="" />
                </div>
                <div className="footer-qrcode-cot">
                  <p>微信客服</p>
                  <img src={customerService} alt="" />
                </div>
              </dd>
            </dl>
          ) : (
            <dl className="footer-qrcode">
              <dt>微信公众号</dt>
              <dd>
                <div className="footer-qrcode-cot">
                  <img src={officialAccount} alt="" />
                  <p>微信公众号</p>
                </div>
                <div className="footer-qrcode-cot">
                  <img src={customerService} alt="" />
                  <p>微信客服</p>
                </div>
              </dd>
            </dl>
          )}
          <div className="footer-icons">
            {isMobile ? (
              <a
                className="icon-box"
                href="https://m.weibo.cn/u/7763030335?jumpfrom=weibocom"
                target={"_blank"}
                rel="noreferrer"
              >
                <span className="iconfont icon-weibo"></span>
              </a>
            ) : (
              <div
                className="icon-box"
                onMouseEnter={() => setHiddenWeibo(false)}
                onMouseLeave={() => setHiddenWeibo(true)}
              >
                <span className="iconfont icon-weibo"></span>
              </div>
            )}
            <a
              className="icon-box"
              href="https://www.zhihu.com/org/xian-yuan-chan-pin-can-mou"
              target={"_blank"}
              rel="noreferrer"
            >
              <span className="iconfont icon-zhihu"></span>
            </a>
            <dl
              className="weibo-code-chart"
              style={{ display: hiddenWeibo ? "none" : "block" }}
            >
              <dt>- 官方微博 -</dt>
              <dd style={{ backgroundImage: `url(${weibo})` }}></dd>
            </dl>
          </div>
        </div>
        <div className="footer-copyright">
          Copyright © www.frontis.cn, All Rights Reserved.
          {isMobile ? <br /> : " "}
          全球智能化服务引领者
          {isMobile ? <br /> : " "}
          <a
            style={{ color: "rgba(255, 255, 255, 0.5)", marginLeft: 4 }}
            href="https://beian.miit.gov.cn"
            target="_blank"
            rel="noreferrer"
          >
            京ICP备2022014486号-1
          </a>
        </div>
        <div className="model-content">
          模型名称:衔远品商大模型 ｜ 备案号：Beijing-PinShang-20240102 ｜
          模型名称:摹小仙｜ 备案号：Beijing-MoXiaoXian-202401002
        </div>
      </footer>

      {showMenuMobile && <MenuMobile setShowMenuMobile={setShowMenuMobile} />}
    </>
  );
});

export default Footer;
